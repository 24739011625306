var avro = require('./avsc');
/* global BigInt */
const longType = avro.types.LongType.__with({
    fromBuffer: (buf) => buf.readBigInt64LE(),
    toBuffer: (n) => {
        const buf = Buffer.alloc(8);
        buf.writeBigInt64LE(n);
        return buf;
    },
    fromJSON: BigInt,
    toJSON: Number,
    isValid: (n) => typeof n == 'bigint',
    compare: (n1, n2) => { return n1 === n2 ? 0 : (n1 < n2 ? -1 : 1); }
}, false);

function deocodeAvroBuffer(buffer, callback) {

    var blob = new Blob([buffer]);
    
    avro.createBlobDecoder(blob, { registry: { 'long': longType } })
        .on('data', function (record) {
            callback(record);
        }).on('error', function (error) {
            console.log(error)
        });

}

async function deocodeAvroFile(file) {
    
    var response = await new Promise(resolve => avro.createBlobDecoder(file, { registry: { 'long': longType } })
        .on('data', function (record) {
            resolve(record);
        }).on('error', function (error) {
            console.log(error)
            resolve (undefined);
        }));
        return response;

}

export { deocodeAvroFile, deocodeAvroBuffer }