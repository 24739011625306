import Papa from 'papaparse'
import * as DataLensJson from "../modules/DataLensJson.js";
import * as AvroDecoder from "../modules/AvroDecoder";

const moment = require("moment");

var metricsAll = []

function adaptMetrics(metricsByTypeRaw, filename) {
  if(metricsByTypeRaw == undefined)
    return
  metricsByTypeRaw.forEach((e) => {
    //console.log("adaptMetrics:metricsByTypeRaw.e.time:", e.time, ",typeof Number(e.time):", typeof Number(e.time))
    if(isNaN(Number(e.time))) {
      e.time = (new Date(e.time).getTime())
      //console.log("adaptMetrics:metricsByTypeRaw.e.time:after:", e.time)
    }
    if(filename != undefined)
      e.filename = filename
      
    // qualipoc EST -> GMT 
    if(e.metricname == 'qualipoc') {
      e.time += 4*3600*1000
    }

    // GS83 -> _G83, compute nr_state 
    if(e.metricname == 'GS83' && e.ServiceStateString !== undefined) {
      //e.metricname = '_G83'
      const regexp = /domain=PS.+nrState=([\w_]+)[ }]/g
      const matched = [...e.ServiceStateString.matchAll(regexp)]
      console.log('adaptMetrics:GS83.ServiceStateString:', e.ServiceStateString, ',matched:', matched)
      if(matched[0] != undefined)
        e.nr_state = matched[0][1]
      else
        e.nr_state = ''
    }

    // DT01: set StartTimestamp -> time
    if(e.metricname == 'DT01') 
      e.time = e.StartTimestamp

    // y_value
    switch(e.metricname) {
      case "RF83": e.y_value = e.asu == undefined ? e.Asu : e.asu; break
      case "NP01_DL": e.y_value = Number(e.linkdownstreambandwidthkbps); break
      case "NP01_UL": e.y_value = Number(e.linkupstreambandwidthkbps); break
      default: e.y_value = 10; break
    }
    // d_value
    switch(e.metricname) {
      case "DT01": e.d_value = Number(e.EndTimestamp) - Number(e.StartTimestamp); break
      case "RF83": e.d_value = e.asu == undefined ? e.Asu : e.asu;  break
      case "NP01_DL": e.d_value = Number(e.linkdownstreambandwidthkbps); break
      case "NP01_UL": e.d_value = Number(e.linkupstreambandwidthkbps); break
      case "_G83": e.d_value = e.value; break
      case "GS83": e.d_value = e.nr_state; break
      case "LC18": e.d_value = e.nr_state; break
      case "GS81": e.d_value = e.datanetworktype == undefined ? e.DataNetworkType : e.datanetworktype; break
      case "qualipoc": e.d_value = e.TechMode; break
      default: e.d_value = ""; break
    }    
  })
}

function joinLC18withGS83(metricsLC18, metricsGS83){
  if(metricsLC18 == undefined || metricsGS83 == undefined) 
    return metricsLC18
  let metricsCombined = metricsLC18.concat(metricsGS83)
  metricsCombined.sort((a, b) => parseInt(a.time) - parseInt(b.time))
  let previousGS83State = ''
  for(let i=0; i< metricsCombined.length; i++) {
    let m = metricsCombined[i]
    if(m.metricname == 'GS83')
      previousGS83State = m.nr_state
    if(m.metricname == 'LC18')
      m.nr_state = previousGS83State
  }
  let LC18withGS83 = metricsCombined.filter(m => m.metricname == 'LC18')
  //console.log("joinLC18withGS83:LC18withGS83:", LC18withGS83)
  return LC18withGS83
}


function readFiles(files, metricsByType, metricsByTypeObj, fileCounts) {
  console.log("readFiles:fileCounts:", fileCounts)
  //let metricsByType = []
  let cntFileTotal = files.length
  console.log("readFiles:cntFileTotal:", cntFileTotal)

  for (let i = 0; i < files.length; i++) {
    var file = files[i]
    var file_ext_name = file.name.split(".").pop()
    //console.log("file_ext_name:", file_ext_name)

    if (file_ext_name == 'csv') {
      Papa.parse(files[i], {
        header: true,
        dynamicTyping: false,
        skipEmptyLines: true,
        complete: function (results) {
          fileCounts.fileOpenedCnt +=1
          //console.log("App:push:i=", i, ", results before adapt:", results)
          adaptMetrics(results.data, file.name)
          //console.log("App:push:i=", i, ", results after adapt:", results)
          fileCounts.fileParsedCnt += 1
          console.log("readFiles:fileCounts:", fileCounts)
          results.data.sort((a, b) => parseInt(a.time) - parseInt(b.time))

          // handle metricsByType[]
          for (let i = 0; i < results.data.length; i++) {
            let metric = results.data[i]
            let key = metric.metricname
            if (! Array.isArray(metricsByTypeObj[key]))
              metricsByTypeObj[key] = []
            metricsByTypeObj[key].push(metric)
            //console.log("i=", i, ",metric=", metric, ",metricsByTypeObj:", metricsByTypeObj)
          }

          metricsByType.length = 0
          for (const key in metricsByTypeObj) {
            console.log("readFiles:metricsByTypeObj:key", key, ", metricsByTypeObj[key].length", metricsByTypeObj[key].length)
            metricsByType.push(metricsByTypeObj[key])
          }
            
          //metricsByType.push(results.data)

          for (let i = 0; i < results.data.length; i++) {
            metricsAll.push(results.data[i])
          }
          metricsAll.sort((a, b) => parseInt(a.time) - parseInt(b.time))
          //console.log('readFiles:after push:metricsByType.length:' + metricsByType.length)
        }
      })
    }
    else if (file_ext_name === "avro") {
      //console.log("file:", file)
      var reader = new FileReader()
      reader.onload = (function(file) {
        return async (e1) => {
        //console.log("onload:e1:", e1)
        
        var p_datalens = { metrics: [] }
        fileCounts.fileOpenedCnt +=1
        //console.log("cnt:", cnt)
        var result = e1.target.result
        var p_json

        result = await AvroDecoder.deocodeAvroFile(file)   

        p_json = JSON.parse(result)
        //console.log("p_json:", p_json)
        DataLensJson.convertAvroJson2DataLensJson(p_json, p_datalens)
        //console.log("p_datalens after convertAvroJson2DataLensJson():", p_datalens)
        
        adaptMetrics(p_datalens.metrics, file.name)

        // handle metricsByType[]
        for (let i = 0; i < p_datalens.metrics.length; i++) {
          let metric = p_datalens.metrics[i]
          let key = metric.metricname
          if (! Array.isArray(metricsByTypeObj[key]))
            metricsByTypeObj[key] = []
          metricsByTypeObj[key].push(metric)
          //console.log("i=", i, ",metric=", metric, ",metricsByTypeObj:", metricsByTypeObj)
        }

        fileCounts.fileParsedCnt += 1
        console.log("fileParsedCnt:", fileCounts)
        if(fileCounts.fileParsedCnt == cntFileTotal) {
          if(metricsByTypeObj["LC18"] != undefined) {
            metricsByTypeObj["LC18"] = joinLC18withGS83(metricsByTypeObj["LC18"], metricsByTypeObj["GS83"])
            adaptMetrics(metricsByTypeObj["LC18"], file.name)
          }
        }
        //console.log("metricsByTypeObj:", metricsByTypeObj)

        metricsByType.length = 0
        for (const key in metricsByTypeObj)
          metricsByType.push(metricsByTypeObj[key])
        //console.log("metricsByType from metricsByTypeObj:", metricsByType)

        // handle metrics[]
        //DataLensJson.prepare_p_datalens(p_datalens)
        //p_datalens.metrics.sort((a, b) => parseInt(a.time) - parseInt(b.time));
        //console.log("p_datalens:", p_datalens)

        for (let i = 0; i < p_datalens.metrics.length; i++) {
          metricsAll.push(p_datalens.metrics[i])
        }
        metricsAll.sort((a, b) => parseInt(a.time) - parseInt(b.time))
        //console.log("metricsAll:", metricsAll)
        };
      })(file)
      reader.readAsText(files[i])
    }
  }
  //console.log("readFiles:metricsByType:", metricsByType)
  //console.log('readFiles:metricsByType.length:' + metricsByType.length)
  return metricsByType, metricsAll
}

function downloadToFile(content, filename, contentType) {
  //console.log("downloadToFile:filename:",filename)
  const a = document.createElement('a');
  const file = new Blob([content], {type: contentType});
  
  a.href= URL.createObjectURL(file);
  a.download = filename;
  a.click();
  
  URL.revokeObjectURL(a.href);
}

function writeMetrics(metrics){
  //console.log("writeMetrics:metrics:",metrics)
  if(metrics == undefined || metrics.length == 0) {
    console.log("WARNING: writeMetrics:metrics is undefined or empty")
    return
  }
  let metricname = metrics[0].metricname
  if(metricname == undefined ) {
    console.log("WARNING: metricname is undefined")
    return
  }
  let metricsStr = ''
  for(let i=0; i< metrics.length; i++) {
    let m = metrics[i]

    if(m.metricname == 'LC18') {
    // lat/lon integer -> float
      m.latitude = parseFloat(m.latitude)/ 1000000.0
      m.longitude = parseFloat(m.longitude) / 1000000.0
    }

    // format time
    m.timeFormatted = moment.utc(parseInt(m.time)).format("YYYY-MM-DD HH:mm:ss.SSS")

    let line = ''

    // write column header row
    if(i == 0) {
      for(let key in m) {
        line += key + ","
      }
      line = line.substr(0,line.length-1) + '\n'
    }

    // write data rows
    for(let key in m) {
      line += m[key] + ","
    }
    line = line.substr(0,line.length-1) + '\n'
    metricsStr += line
  }

  //console.log("metricsStr:", metricsStr)
  downloadToFile(metricsStr, metricname + ".csv", 'text/csv;charset=utf-8;')
}

function writeMetricsByType(metricsByType){
  if(metricsByType == undefined) {
    console.log("metricname: writeMetricsByType:metricsByType is undefined")
    return
  }
  //console.log("writeMetricsByType:metricsByType:",metricsByType)
  let i = 0
  for(let key in metricsByType) {
    //console.log("key=:", key)
    setTimeout(() => {  writeMetrics(metricsByType[key]); }, i++*500);
  }
}

export {readFiles, writeMetricsByType}