// var p_datalens = { 'metrics' : [] }

function parseMetric(key, item, p_datalens) {
    var new_item = { 'type': '32-bit', 'time': item.time._text, 'metricID': key }
    for (var key2 in item) { // loop thru metric fields
        if (key2 == 'time' || key2 == 'RAW_BIN') continue

        var field_val = item[key2]._text
 
        new_item[key2] = field_val
    } // loop thru metric fields
    p_datalens.metrics.push(new_item)
}

function convertSnapshot(event, p_datalens) {
    var snapshot = event.snapshot
    for (var key in snapshot) { // loop thru metric and metric arrays in snapshot
        var item = snapshot[key]
        if (Array.isArray(item)) { 
            item.forEach(function (item2) { // loop thru metrics
                parseMetric(key, item2, p_datalens)
            }) // loop thru metrics
        } 
        else { // single metric
            parseMetric(key, item, p_datalens)
        }
    } // loop thru metric and metric arrays in snapshot
}

function convertPkgXmlJson2DataLensJson(p, p_datalens) {
    var events = p.sqaPackage.packageNode.event

    if (Array.isArray(events)) 
        events.forEach((event) => { 
            convertSnapshot(event, p_datalens)
        }) // loop thru events       
    else
        convertSnapshot(events, p_datalens)
}

function convertAvroJson2DataLensJson (p, p_datalens) {
    for (var key in p) { // loop thru metric arrays
        if (Array.isArray(p[key])) { 
            p[key].forEach(function (item) { // loop thru metrics
                var new_item = {'time': item.time, 'metricname': key }
                for (var key2 in item.payload) { // loop thru metric fields
                    var field_val = item.payload[key2]
                    if(Array.isArray(field_val)) {
                        //console.log("Skipping array field:", index, item, item.payload[key2])
                    }
                    else if (field_val instanceof Object) {
                        for (var key3 in field_val) {
                            var field_obj_val = field_val[key3]
                            if(Array.isArray(field_obj_val)) {
                                //console.log("Skipping array field:", key, key2, key3)
                                new_item[key2] = 'Array'
                            }
                            else {
                                new_item[key2] = field_obj_val
                            }                            
                        }                   
                    }
                    else {
                        new_item[key2] = field_val
                    }
                } // loop thru metric fields
                //console.log(new_item)
                p_datalens.metrics.push(new_item)
            })
        } // loop thru metrics
    } // loop thru metric arrays
    
}

/*
function prepare_p_datalens(p_datalens) {
    // sort metrics by time
    //console.log("p_datalens before sort:",p_datalens )
    p_datalens.metrics.sort((a, b) => parseInt(a.time) - parseInt(b.time));
    //console.log("p_datalens after sort:", p_datalens)
    
    // make each metric an array 
    
    for (var key in p_datalens.metrics) {
        var item_t = []
        item_t.push(p_datalens.metrics[key])
        //console.log("key:", key, ",item_t:", item_t)
        p_datalens.metrics[key] = item_t
    }
    //console.log("p_datalens at the end:", p_datalens)
}*/

export {convertPkgXmlJson2DataLensJson, convertAvroJson2DataLensJson}

/*
exports.convertPkgXmlJson2DataLensJson = convertPkgXmlJson2DataLensJson
exports.convertAvroJson2DataLensJson = convertAvroJson2DataLensJson
exports.prepare_p_datalens = prepare_p_datalens
*/