<template>
  <el-container id="omlens" class="wrapper">
    <el-header style="text-align: left; font-size: 12px; height: 35px;border-radius: 4px;">
      <img src="./assets/gws_logo.png" style="max-height:100%;">
    </el-header>
    <el-row>
      <el-col :span="4"><div class="grid-content"><input type="file" id="inputDeviceInfo" @change="handleFiles" multiple></div></el-col>
      <el-col :span="2"><div class="grid-content">Files Opened: {{fileCounts.fileOpenedCnt}}</div></el-col>
      <el-col :span="2"><div class="grid-content">Files Parsed: {{fileCounts.fileParsedCnt}}</div></el-col>
      <el-col :span="1" :offset="15"><button style="font-size: 13px;" @click="exportMetrics" type="button">Export</button></el-col>
    </el-row>
    <!--DeviceInfo :deviceInfo='deviceData'></!--DeviceInfo-->
    <div id="charts"><DataPanel :metricsByType='metricsByType' :metricsAll='metricsAll'></DataPanel></div>
    <!--div class="footer"><span>Copyright © 2018 The Nielsen Company (US), LLC. All Rights Reserved.</span></!--div-->
  </el-container>
</template>

<script>
import { reactive } from 'vue'
//import DeviceInfo from './components/DeviceInfo'
import DataPanel from './views/DataPanel'
import {readFiles, writeMetricsByType} from './services/localfileservices.js'

export default {
  name: 'app',
  components: {
    //DeviceInfo,
    DataPanel
  },
  setup () {
    const metricsByType = reactive({value:[]})  
    let metricsByTypeObj = {}
    let metricsAll = reactive({value:[]}) 
    let fileCounts = reactive({fileOpenedCnt: 0, fileParsedCnt: 0})

    const handleFiles = (e) => {
      console.log("App:setup():fileCounts:", fileCounts)
      metricsByType.value, metricsAll.value = readFiles(e.target.files, metricsByType.value, metricsByTypeObj, fileCounts)
      //console.log('App:metricsByType:', metricsByType)
      //console.log('App:metricsByType.length:' + metricsByType.value.length)
      //console.log('App:metricsAll:', metricsAll)
    }

    const exportMetrics = () => {
      writeMetricsByType(metricsByType.value)
      console.log('App:exportMetrics:metricsAll:', metricsAll)
    }

    return {
      metricsByType,
      metricsAll,
      handleFiles,
      exportMetrics,
      fileCounts
    }
  }
}
</script>

<style>
  .el-header {
    background-color: #d6e8fa;
    color: #333;
    line-height: 60px;
  }
  .el-row {
    margin: 1px;
    padding: 2px;
    margin: 2px;
    font-size: 12px;
    /*color: #60aaf5;*/
    font-family: "Tahoma", "Arial", sans-serif;
  }
  .el-col {
    border-radius: 4px;

  }
  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap:0.2em;
    grid-row-gap:0.4em;
    height:minmax(400px,auto);
    border:none;
  }
  .wrapper > div {
    background:#fff;
    padding:0;
  }
  .header img {
    padding-top:6px;
    padding-left:6px;
    height: 2em;
  }
  .footer {
    text-align:center;
    padding-top:10px;
    height:32px;
  }
  .footer span {
    padding-top:8px;
    font-family:Arial, Helvetica, sans-serif;
    font-size:12px;
  }
</style>




