<style>
.input-select {
  display: grid;
}
.input-metric-filter {
  grid-column-start: 1;
  grid-column-end: 3;
  margin-bottom: 3px;
}
.view-select {
  grid-column-start: 50;
  grid-column-end: 50;
}

.metric-table {
  max-height:400px;
  height: auto;
  width: 1660px;
  overflow: auto;
  border: 1px solid #eaeee7;
  margin-bottom: 2px;
  margin-bottom: 5px;
}
.metric-container {
  display: flex; /* or inline-flex */
  padding: 2px;
  margin: 2px;
  font-size: 10px;
  font-family: "Tahoma", "Arial", sans-serif;
}

.th {
  border: 1px solid #f3f5f8;
  padding: 6px;
  background-color: #d6e8fa;
}

.td {
  border: 1px solid #d6e8fa;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 3px;
  padding-right: 3px;
}

.chart-wrapper {
  max-height:400px;
  height: auto;
  overflow-y: scroll;
  border: 1px rgb(238, 231, 231) solid;
  margin-top: 10px;
}

.chart {
  display: grid;
  grid-template-columns: 1fr 11fr;
  grid-column-gap: 0.1em;
  justify-items: stretch;
  align-items: stretch;
}

.chart-label {
  border: 1px rgb(238, 231, 231) solid;
  text-align: center;
  padding: 10px;
  padding-top: 60px;
  word-break: break-all;
  font-size: small;
  font-family: "Tahoma", "Arial", sans-serif;
}
.chart-content {
  border: 1px rgb(238, 231, 231) solid;
}
</style>

<template>
  <div>
    <!-- input metric filter -->
    <div class="input-select">
      <div>
        <el-input size="small" placeholder="Enter metric name(s)" v-model="metricFilterStr" class="input-metric-filter"></el-input>
      </div>
      <div class="view-select">
        <el-checkbox v-model="metricViewSelected">Metric View</el-checkbox>
        <el-checkbox v-model="timelineViewSelected">Timeline View</el-checkbox>
      </div>
    </div>
    
    <div id="metricTable" class="metric-table" :style="styleMetricTable()">
      <div v-for="(data, index) in metricsAll.value" :key="index">
        <div :style="{ display: checkMetricVisible(data, metricFilterStr)?'block':'none'}">
          <div class="metric-container">
            <div v-for="(item, key, index) in data" :key="index">
              <div>
                <div class="th">{{ key }}</div>
                <div class="td">{{ convertJulian2GMT(key, item) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div id="chart-wrapper" class="chart-wrapper" :style="styleTimelineView()">
      <div class="chart" :style="{ display: metricFiltering(metricsByType[0], metricFilterStr)?'grid':'none'}"
            v-for="(metricsByType) in metricsByTypeAll.value" :key="metricsByType[0].metricname">
          <div class="chart-label">
            {{ metricsByType[0].metricname }}
          </div>
          <div :id="'chart-content-' + metricsByType[0].metricname" class="chart-content" v-on:wheel="handleWheel">
            <TimelineView
              :metricsByType="metricsByType"
              :curr_time_min_all="curr_time_min_all"
              :curr_time_max_all="curr_time_max_all"
              @brush_end='handle_brush_end'
              :sourceChartMetricname="sourceChartMetricname"
            />
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import { reactive, watch, ref, toRaw } from "vue";
import TimelineView from "../views/TimelineView";

const moment = require("moment");

var metricsByTypeAll = reactive({ value: [] });

let time_min_all_orig;
let time_max_all_orig;

let wheel_cnt = 0;

export default {
  components: {
    TimelineView,
  },
  props: ["metricsByType", "metricsAll"],
  setup(props) {
    let curr_time_min_all = ref(Number.MAX_SAFE_INTEGER);
    let curr_time_max_all = ref(0);
    let metricFilterStr = ref("")
    let metricViewSelected = ref(false)
    let timelineViewSelected = ref(true)
    let sourceChartMetricname = ref('')

    //console.log("DataPanel:setup:props.metricsByType:", props.metricsByType);
    //console.log("DataPanel:setup:props.metricsAll:", props.metricsAll);

    watch(
      () => props.metricsByType,
      (metricsByType) => {
    /*watchEffect( () => {
        let metricsByType = props.metricsByType*/
        console.log("DataPanel:watch:props.metricsByType:", props.metricsByType)

        //metricsAll.value = props.metricsAll
        metricsByTypeAll.value.length = 0;
        for (let i = 0; i < metricsByType.value.length; i++) {
          if (metricsByType.value[i].length == 0) continue;
          let metricsByTypeRaw = toRaw(metricsByType.value[i]);
          metricsByTypeAll.value.push(metricsByTypeRaw);
        }
        //console.log("metricsByTypeAll.value:processed data:", metricsByTypeAll.value);
        metricsByTypeAll.value.sort((a, b) => {
          let a1 = a[0].metricname,
            b1 = b[0].metricname;
          return a1 == b1 ? 0 : a1 > b1 ? 1 : -1;
        });

        // compute time min/max of all data
        let timeMinMax = updateMinMax(metricsByTypeAll.value);
        time_min_all_orig = timeMinMax[0];
        time_max_all_orig = timeMinMax[1];
        curr_time_min_all.value = timeMinMax[0];
        curr_time_max_all.value = timeMinMax[1];
        //console.log("DataPanel:watch:after updateMinMax:min_max_all:after:",curr_time_min_all,curr_time_max_all);
      },
      { deep: true }
    );

    function styleMetricTable() {
      let visible = metricViewSelected.value?'block':'none'
      let height = timelineViewSelected.value?'400px':'800px'
      let styleStr = 'display: ' + visible + ';max-height: ' + height
      //console.log("styleMetricTable:styleStr:", styleStr)
      return styleStr
    }

    function styleTimelineView() {
      let visible = timelineViewSelected.value?'block':'none'
      let height = metricViewSelected.value?'400px':'800px'
      let styleStr = 'display: ' + visible + ';max-height: ' + height
      //console.log("styleTimelineView:styleStr:", styleStr)
      return styleStr
    }

    function handleWheel(event) {
      //console.log("wheel:", event);
      //input: event, wheel_cnt, time_min_all, time_max_all, time_min_all_orig, time_max_all_orig
      //output: wheel_cnt = 0 , updateAll(svg, x, y)
      //console.log("wheel:", wheel_cnt, "event:", event); //,"deltaY:",d.deltaY)
      let currX = d3.pointer(event)[0];
      let scrollDirection = event.deltaY < 0 ? 1 : -1;
      let results = adjustTimeMinMax(
        currX,
        wheel_cnt,
        scrollDirection,
        curr_time_min_all.value,
        curr_time_max_all.value,
        time_min_all_orig,
        time_max_all_orig
      );
      wheel_cnt = results[0];
      curr_time_min_all.value = results[1];
      curr_time_max_all.value = results[2];
      //console.log("DataPanel:onUpdated:after adjustTimeMinMax:min_max_all:after:",curr_time_min_all,curr_time_max_all);
    }

    function convertJulian2GMT(fieldName, fieldValue) {
      // format timeInMilliSec
      // 1571514380031, October 19, 2019 7:46:20.031 PM, 2019-10-19 19:46:20.031.+0000
      // let ms = timeInMilliSec % 1000
      if (fieldName === "time") {
        let date = moment.utc(parseInt(fieldValue)).format("YYYY-MM-DD HH:mm:ss.SSS");
        // console.log(date);
        return date;
      } else {
        if (fieldValue == undefined || fieldValue == "") fieldValue = "null";
        return fieldValue;
      }
    }

    function checkMetricVisible(metricData, metricFilterStr) {
      //console.log("checkMetricVisible:metricData:", metricData)
      //console.log("checkMetricVisible:time:", metricData.time, ", curr_time_min_all:", curr_time_min_all.value, ", curr_time_max_all:", curr_time_max_all.value);
      
      if ( metricData.time >= curr_time_min_all.value &&
           metricData.time <= curr_time_max_all.value &&
           metricFiltering(metricData, metricFilterStr)
      )
        return true
      else return false
    }

    function metricFiltering(metricData, filterStr) {
      //console.log("metrciFiltering():metricData:", metricData, ",filterStr:", filterStr)
      if (filterStr == undefined) return true
      let ret
      filterStr = filterStr.trim();
      if (filterStr == "") return true;

      // remove the trailing ',' if exists which would disable filtering
      if (filterStr.length > 1 && filterStr[filterStr.length - 1] == ",") {
        filterStr = filterStr.slice(0, -1);
      }

      var m = new RegExp(
        filterStr
          .toUpperCase()
          .split(",")
          .map((item) => item.trim())
          .join("|")
      );
      //console.log("metricData.metricname:", metricData.metricname, ", m:", m, ",match():", metricData.metricname.match(m))
      if (metricData.metricname.toUpperCase().match(m) != null ||
        (metricData.appUid != undefined &&
          metricData.appUid.toString().match(m) != null)
      )
        ret = true;
      else ret = false;
      //console.log("metrciFiltering():metricData.metricname:", metricData.metricname, ",ret:", ret)
      return ret
    }
    
    function handle_brush_end(selection){
      console.log("handle_brush_end:selection:", selection)
      curr_time_min_all.value = selection[0]
      curr_time_max_all.value = selection[1]
      sourceChartMetricname.value = selection[2]
    }

    return {
      metricsByTypeAll,
      curr_time_min_all,
      curr_time_max_all,
      handleWheel,
      convertJulian2GMT,
      checkMetricVisible,
      metricFiltering,
      metricFilterStr,
      metricViewSelected,
      timelineViewSelected,
      styleMetricTable,
      styleTimelineView,
      handle_brush_end,
      sourceChartMetricname
    };
  },
};

function updateMinMax(metricsByType) {
  let time_min_all = Number.MAX_SAFE_INTEGER;
  let time_max_all = 0;
  //console.log("DataPanel:updateMinMax:metricsByType:",metricsByType);
  for (let i = 0; i < metricsByType.length; i++) {
    let metricsByTypeRaw = metricsByType[i];
    // get min/max of current data
    let time_min = d3.min(metricsByTypeRaw, function (d) {
      return d.time;
    });
    let time_max = d3.max(metricsByTypeRaw, function (d) {
      return d.time;
    });
    //console.log("DataPanel:updateMinMax:min_max_all:i=", i, ", time_min,time_max", time_min, time_max);

    time_min_all = Math.min(time_min, time_min_all);
    time_max_all = Math.max(time_max, time_max_all);
  }
  //console.log("DataPanel:updateMinMax:min_max_all:after:",time_min_all,time_max_all);
  return [time_min_all, time_max_all];
}

function adjustTimeMinMax(
  currX,
  wheel_cnt,
  scrollDirection,
  time_min_all,
  time_max_all,
  time_min_all_orig,
  time_max_all_orig
) {
  //input: event, wheel_cnt, time_min_all, time_max_all
  //output: wheel_cnt = 0 , updateAll(svg, x, y)
  //console.log("adjustTimeMinMax:scrollDirection:", scrollDirection)
  //console.log("adjustTimeMinMax:time_min_all,time_max_all:", time_min_all, time_max_all)
  //console.log("adjustTimeMinMax:time_min_all,time_max_all_orig:", time_min_all_orig, time_max_all_orig)

  let leftPct = (currX - 40) / (1300 - 40 - 30);
  if (currX <= 40) leftPct = 0;
  if (currX >= 1270) leftPct = 1;
  let rightPct = 1 - leftPct;

  if (wheel_cnt++ > 2) {
    // scroll up
    //console.log("time_min_all,time_max_all:before:",time_min_all,time_max_all)
    time_min_all =
      time_min_all +
      scrollDirection * (time_max_all - time_min_all) * 0.03 * leftPct * wheel_cnt;
    time_max_all =
      time_max_all -
      scrollDirection * (time_max_all - time_min_all) * 0.03 * rightPct * wheel_cnt;
    //console.log("adjusted:time_min_all,time_max_all:after:",time_min_all,time_max_all)
    if (time_min_all < time_min_all_orig) time_min_all = time_min_all_orig;
    if (time_max_all > time_max_all_orig) time_max_all = time_max_all_orig;
    //console.log("time_min_all,time_max_all:after:",time_min_all,time_max_all)
    wheel_cnt = 0;
  }
  return [wheel_cnt, time_min_all, time_max_all];
}
</script>
