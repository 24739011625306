<style>
.rect {
  stroke: none;
  stroke-width: 1px;
  /*fill:red;*/
}

div.tooltip {	
    position: absolute;			
    text-align: center;			
    width: 60px;					
    height: 28px;					
    padding: 2px;				
    font: 12px sans-serif;		
    background: lightsteelblue;	
    border: 0px;		
    border-radius: 8px;			
    pointer-events: none;			
}
</style>

<template>
    <div id = "timelineView">
    </div>
</template>

<script>
import * as d3 from 'd3'
import {onMounted, watch} from 'vue'

// set chart margins and compute chart width/height
let margin = {
  top: 10,
  right: 40,
  bottom: 20,
  left: 30
}

let chartWidth = 1300
let width = chartWidth - margin.left - margin.right
let height = 40

var x, y, svg, xAxis, yAxis
var metricname, brush, curr_time_min_all, curr_time_max_all
var my_emit//, brushSvg

export default {
  props: [ 'metricsByType', 'curr_time_min_all', 'curr_time_max_all', 'sourceChartMetricname'],
  setup (props, {emit}) {
    my_emit = emit
    onMounted(() => {
      metricname = props.metricsByType[0].metricname
      console.log("onMounted:props.metricsByType[0].metricname:", metricname)
      curr_time_min_all = props.curr_time_min_all
      curr_time_max_all = props.curr_time_max_all

      svg = d3.select("#chart-content-" + metricname).select("#" + "timelineView").attr("id","timelineView_" + metricname)
      // *** add svg 
      svg =  svg.append("svg")
          .attr("viewBox", "0 0 " + (width + margin.left + margin.right) + " " + (height + margin.top + margin.bottom))

      // *** add g-group (x-axis, y-axis, rects)
      svg = svg.append("g")
          .attr("id","chart-container-"+metricname)
          .attr("transform","translate(" + margin.left + "," + margin.top + ")");

      // Add X axis
      x = d3.scaleUtc()
        .domain(d3.extent(props.metricsByType, function(d) { return d.time; }))
        .range([ 0, width ]);

      xAxis = svg.append("g")
        .attr("id", "xAxis-" + metricname)
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x));

      // Add Y axis
      y = d3.scaleLinear()
        .domain([0, d3.max(props.metricsByType, function (d) {return d.y_value})])
        .range([height, 0])

      let metricName = props.metricsByType[0].metricname 
      if (metricName == 'RF83' || metricName == 'NP01_DL' || metricName == 'NP01_UL') {
        yAxis = svg.append("g")
          .attr("id", "yAxis-" + metricname)
          .call(d3.axisLeft(y).ticks(4).tickFormat(d3.format(".0s")));
      }
      
      // Add brushing
      brush = d3.brushX()                 // Add the brush feature using the d3.brush function
        .extent( [ [0,0], [width,height] ] ) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
        .on("end", updateChart) // Each time the brush selection changes, trigger the 'updateChart' function
 
      // Add the brushing
      //brushSvg = 
      svg
        .append("g")
        .attr("id", "brush-" + metricname)
        .call(brush)

      updateTimelineView(props.metricsByType, props.curr_time_min_all, props.curr_time_max_all)
    })
    watch(
      () => [props.metricsByType, props.curr_time_min_all, props.curr_time_max_all],
      ([metricsByType, curr_time_min_all, curr_time_max_all]) => {
        //console.log("watch:metricsByType[0].metricname:", metricsByType[0].metricname)
        //console.log("watch:sourceChartMetricname:", sourceChartMetricname)
        //if(sourceChartMetricname === metricname)
          //d3.select('#chart-container-' + sourceChartMetricname).call(brush.move, null)

        curr_time_min_all = props.curr_time_min_all
        curr_time_max_all = props.curr_time_max_all

        updateTimelineView(metricsByType, curr_time_min_all, curr_time_max_all)
      },
      { deep: true }
    )
  }   
}        

// A function that set idleTimeOut to null
var idleTimeout
function idled() { idleTimeout = null; }

function updateChart(event) {
  //console.log("updateChart:metricname:", metricname, ", event:", event)
  //console.log("updateChart:event.sourceEvent:", event.sourceEvent)
  //rect, g#chart-container, svg, div#timelineView_NP01_UL, div#chart-content-NP01_UL.chart-content, div.chart, div#chart-wrapper.chart-wrapper, div, div#charts, section#omlens.el-container.is-vertical.wrapper, div#app, body, html, document, Window]
  let sourceChartName = '', sourceChartMetricname = ''
  if(event.sourceEvent != undefined) {
    console.log("updateChart:event.sourceEvent.path:", event.sourceEvent.path)
    sourceChartName = event.sourceEvent.path[3].id
    sourceChartMetricname = sourceChartName.substr(sourceChartName.indexOf("_") + 1)
    console.log("updateChart:sourceChartName.indexOf('_'):", sourceChartName.indexOf("_") + 1)
    console.log("updateChart:sourceChartMetricname:", sourceChartMetricname)
  }

  let extent = event.selection
  //console.log("updateChart:extent:", extent)

  // If no selection, back to initial coordinate. Otherwise, update X axis domain
  if(!extent){
      if (!idleTimeout) return idleTimeout = setTimeout(idled, 350); // This allows to wait a little bit
      //x.domain([curr_time_min_all, curr_time_max_all])
      my_emit('brush_end',[ curr_time_min_all, curr_time_max_all, sourceChartMetricname])
  }else{
      let time_min = x.invert(extent[0]), time_max = x.invert(extent[1])
      //x.domain([ time_min, time_max ])  
      my_emit('brush_end',[ time_min, time_max, sourceChartMetricname ])
      d3.select('#chart-container-' + sourceChartMetricname).call(brush.move, null) // This remove the grey brush area as soon as the selection has been done
      d3.select('#chart-container-' + metricname).call(brush.move, null) // This remove the grey brush area as soon as the selection has been done
  }
}

// input: x, y, metricsByType, i, time_min_all, time_max_all
function updateTimelineView(metricsByType, curr_time_min_all, curr_time_max_all) {
  metricname = metricsByType[0].metricname
  //console.log("updateTimelineView:metricname:", metricname, "metricsByType.length:", metricsByType.length)

  xAxis = d3.select("#" + "xAxis-" + metricname)
  // *** adjust x axis  
  x.domain([curr_time_min_all, curr_time_max_all])
  xAxis.call(d3.axisBottom(x))

  // *** adjust y axis 
  let metricsFiltered = metricsByType.filter(d => {return d.time >= curr_time_min_all && d.time <= curr_time_max_all})
  let domain_max = d3.max(metricsFiltered, d => {return d.y_value})
  y.domain([0, domain_max]);
  if (metricname == 'RF83' || metricname == 'NP01_DL' || metricname == 'NP01_UL') {
    yAxis = d3.select("#" + "yAxis-" + metricname)
    yAxis.call(d3.axisLeft(y).ticks(4).tickFormat(d3.format(".0s")))
  }
  
  // Define the div for the tooltip
  var div = d3.select("body").append("div")	
      .attr("class", "tooltip")				
      .style("opacity", 0)

  var formatTime = d3.timeFormat("%B %d");
  
  let chart = 
    d3.select("#timelineView_"+metricname).select("#chart-container-" + metricname)
      .selectAll(".rect")
      .data(metricsByType, d => d.cognitoid+d.metricname+d.time)  // join data
      .attr("x", function (d) {
        return x(d.time);
      })
      .attr("width", function (d) {
        if(d.metricname == 'DT01') {
          //console.log("chart.enter():d.EndTimestamp:", d.EndTimestamp, ", x(d.Endtimestamp):", x(d.EndTimestamp), ", x(d.Starttimestamp):", x(d.StartTimestamp))
          return x(d.EndTimestamp) - x(d.StartTimestamp)
        }
        else
        return 2 
      })
      .attr("y", function (d) {
        return y(d.y_value)
      })
      .attr("height", function (d) {
        return height - y(d.y_value)
      })


  chart.exit().remove()

  let chartEnter = chart.enter()
      .append("rect")
      .attr("class","enter")
      .attr("class","rect")
      .attr("x", function (d) {
        //console.log("chart.enter().attr.x.d.time:", d.time, ", x(d.time):", x(d.time));
        return x(d.time);
      })
      .attr("y", function (d) {
        return y(d.y_value)
      })
      .attr("width", function (d) {
        if(d.metricname == 'DT01') {
          console.log("chart.enter():d.StartTimestamp:",d.StartTimestamp,", d.EndTimestamp:", d.EndTimestamp, ", x(d.Endtimestamp):", x(d.EndTimestamp), ", x(d.Starttimestamp):", x(d.StartTimestamp))
          return x(d.EndTimestamp) - x(d.StartTimestamp)
        }
        else
        return 2 
      })
      .attr("height", function (d) {
        return height - y(d.y_value)
      })
      .attr("opacity", 0.4)
      .attr("fill", function (d) { return mapNetTypeToColor(d) })
      
      .on("mouseover", function(event, d) {		
            div.transition()		
                .duration(200)		
                .style("opacity", .9);		
            div.html(formatTime(d.time) + "<br/>"  + d.d_value)	
                .style("left", (event.pageX) + "px")		
                .style("top", (event.pageY - 28) + "px");	
            })					
        .on("mouseout", function() {		
            div.transition()		
                .duration(500)		
                .style("opacity", 0);	
        });

  chart.merge(chartEnter)

}

function mapNetTypeToColor(d) {
  var color = "red"
  if (d.metricname == "IP21" || d.metricname == "IP23" || d.metricname == "IP23") {
    if (d.lnettype == "NETWORK_TYPE_WIFI")
      color = "orange"
    else if (d.lnetsubtype == "NETWORK_TYPE_NR")
      color = "green"
    else if (d.lnetsubtype == "NETWORK_SUBTYPE_LTE")
      color = "blue"
    else
      color = "grey"
  }
  else if (d.metricname == "GS81" ) {
    console.log("mapNetTypeToColor:'datanetworktype' in d:", 'datanetworktype' in d, 'datanetworktype' in d ? d.datanetworktype : d.DataNetworkType)
    switch(Number('datanetworktype' in d ? d.datanetworktype : d.DataNetworkType)) {
      case 20: color = "green"; break
      case 13: color = "blue"; break
      default:  color = "grey"
    }
  }
  else if (d.metricname == "GS83" || d.metricname == "LC18") {
    switch(d.nr_state) {
      case "CONNECTED": color = "green"; break
      case "RESTRICTED": color = "purple"; break
      case "NOT_RESTRICTED": color = "lightgreen"; break
      case "NONE": color = "black"; break
      default:  color = "grey"
    }
  }
  else if (d.metricname == "_G83") {
    switch(d.value) {
      case "CONNECTED": color = "green"; break
      case "RESTRICTED": color = "purple"; break
      case "NOT_RESTRICTED": color = "lightgreen"; break
      case "NONE": color = "black"; break
      default:  color = "grey"
    }
  }
  else if (d.metricname == "LC10" ) {
    switch(d.cellconnectionstatus) {
      case "CONNECTION_SECONDARY_SERVING": color = "green"; break
      case "CONNECTION_PRIMARY_SERVING": color = "blue"; break
      case "CONNECTION_NONE": color = "black"; break
      default:  color = "grey"
    }
  } 
  else if (d.metricname == "UI0A_networktype" || d.metricname == "UI0A_networktype" || d.metricname == "UI0A_networktype") {
    switch(d.networktype) {
      case "NETWORK_TYPE_NR": color = "green"; break
      case "NETWORK_TYPE_LTE": color = "blue"; break
      case "NETWORK_TYPE_NONE": color = "black"; break
      default:  color = "grey"
    }
  } 
  else if (d.metricname == "UI0A_overridenetworktype" || d.metricname == "UI0A_overridenetworktype") {
    switch(d.overridenetworktype) {
      case "OVERRIDE_NETWORK_TYPE_NR_NSA": color = "green"; break
      case "OVERRIDE_NETWORK_TYPE_LTE_CA": color = "blue"; break
      case "OVERRIDE_NETWORK_TYPE_NONE": color = "black"; break
      default:  color = "grey"
    }
  }
  else if (d.metricname == "UI0A") {
    switch(d.OverrideNetworkType) {
      case "OVERRIDE_NETWORK_TYPE_NR_NSA": color = "green"; break
      case "OVERRIDE_NETWORK_TYPE_LTE_CA": color = "blue"; break
      case "OVERRIDE_NETWORK_TYPE_NONE": color = "black"; break
      default:  color = "grey"
    }
  }  else if (d.metricname == "RF83" || d.metricname == "LC83") {
    color = "blue"
  }
  else if (d.metricname == "qualipoc") {
    switch(d.TechMode) {
      case "LTE-5GNR": color = "green"; break
      case "LTE CA-5GNR": color = "darkgreen"; break
      case "LTE CA": color = "darkblue"; break
      case "LTE": color = "blue"; break
      default:  color = "grey"
    }
  }  
  else if (d.metricname == "DT01") {
    switch(d.Job) {
      case "SocketEstablishment": color = "red"; break
      case "DnsLookup": color = "green"; break
      case "Handshake": color = "blue"; break
      case "Transfer": color = "white"; break
      default:  color = "grey"
    }
  }    
  return color
}

</script>

